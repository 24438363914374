<template>
 <base-main-container>
    <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="68">
          AI is preparing your resource
        </v-progress-circular>
      </v-overlay>
       <template v-slot:centered>
      <v-card class="my-card rounded-lg" elevation="0">
        <v-card-title>Email Composer 
        
        </v-card-title>
        <v-card-text>
               
          <v-textarea

             v-model="letterTopics"
            outlined
            label="Please tell briefly about what you want to communicate/respond"
          >
          </v-textarea>
           <v-textarea
             v-model="inputText"
            outlined
             label=  "If you will be responding to an email, Plese enter the email to be responded here"
          >
          </v-textarea>

        </v-card-text>
         <v-combobox
            class="mt-6 ml-2"
            append-icon="mdi-filter"
            v-model="tone"
            :items="['Friendly', 'Cold', 'Neutral','Official', 'Legal']"
            label="Tone"
            outlined
            clearable
             @input.native="tone=$event.srcElement.value"
          />
        <v-card-actions>
          <v-spacer />
            <v-btn class="mr-2" outlined link to="newresource">
              <v-icon class="mr-2"> mdi-backspace</v-icon> Cancel
            </v-btn>

          <v-btn
            :disabled="!letterTopics"
            color="#212121"
            @click="sendRequest()"
          >
            
            <h3  style="color: white">Generate please</h3>
          </v-btn>

          <v-spacer />
        </v-card-actions>
      </v-card>
  
       </template>
     
   <v-snackbar
          v-model="showSnackbar"
         :timeout="6000"
           color="warning"
          top
        >
          {{ snackbarMessege }}
        </v-snackbar>
</base-main-container>
</template>

<script>

import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseAuth } from "../../firebase";
import { db } from "../../firebase";
export default {
 
  data() {
    return {
      what: "Write an Email",
     
       tone: null,
      
      isLoading: false,
    //  title: "",
      letterTopics: null,
     
      inputText:"",
  
      showSnackbar:false,
      snackbarMessege:"",
     
    };
  },
//   computed:{
//      premium(){
//       return this.$store.state.subscription ? this.$store.state.subscription.status =='active' 
//       || this.$store.state.subscription.status == 'active school'
//        || this.$store.state.subscription.status == 'trialing'
//       : false
//     }
//   },
  methods: {
  
    async sendRequest() {
      this.isLoading = true;
     
        let prompt =""
        if(this.inputText){
        prompt = 'I received this email and I need help to respond: '+this.inputText +". Please use these points in your response: "+ this.letterTopics+" .Please use a "+this.tone+" tone and jargon"
       
        }
        else{
          prompt = "can you write and email for me with these points: "+this.letterTopics+" .Please use a "+this.tone+" tone and jargon"
         
        }
       
        

let uid = firebaseAuth.currentUser.uid;

let newresource ={
    what: this.what,
       
        lastsaved: Date.now(),
         promt: [{"role": "system","content": "You are an excellent educator, teacher and communicator."},
                 {"role": "user", "content":prompt},
        ],
        
        uid:uid
        
}
 
try {
          const userRef = db.collection("customers").doc(uid);
          const subcollectionRef = userRef.collection("saved");

          const docRef = await subcollectionRef.add(newresource)
          //console.log(docRef.id);
          newresource.id=docRef.id
        } catch (error) {
          console.error("Error adding document:", error);
        }

const functions = getFunctions();

      try { 
      const functionRef = httpsCallable(
        functions,
        "callopenai"
      );
      functionRef(newresource);
       
        this.isLoading = false;
        this.saveDialog = false;
        this.$router.push("/mydocs");

}catch (error) {
      this.isLoading = false;
      this.showSnackbar = true
      this.snackbarMessege = "The system is overloaded, please try again" 
}


    },

    clearSelections() {
      
        this.tone = null
       
        this.letterTopics = null
       
    },
     
  },
  
};
</script>
<style scoped>

    .centered {
        border-radius: 15px;  
        background-color:#f3f6fc; 
        display: flex;
        justify-content: center;
        max-width: 1200px;
        height: 90vh; /* Adjust this value to match your desired height */
       
    }
    @media (min-width: 600px) {
  .my-card {
    
    width: 300px;
  }
}
@media (min-width: 960px) {
  .my-card {
     
    width: 500px;
  }
}
@media (min-width: 1264px) {
  .my-card {
    
    width: 800px;
  }
}
    .container.fill-height {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}


</style>




